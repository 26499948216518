import { set } from "date-fns";
import React, { useState, useCallback, useEffect } from "react";

type TimePickerProps = {
  initialTime?: Date;
  onTimeSelected: (time: Date) => void;
  baseDate: Date;
};

const TimePicker: React.FC<TimePickerProps> = ({ initialTime = new Date(), onTimeSelected, baseDate }) => {
  const [time, setTime] = useState(new Date(initialTime));
  const hours = Array.from(new Array(12), (_, i) => i + 1);
  const minutes = Array.from(new Array(12), (_, i) => i * 5);
  const periods = ["AM", "PM"] as const;

  const selectedHour = time.getHours() % 12 || 12;
  const selectedMinute = Math.floor(time.getMinutes() / 5) * 5;
  const selectedPeriod = time.getHours() >= 12 ? "PM" : "AM";

  useEffect(() => {
    setTime((prevTime) => {
      return set(prevTime, {
        year: baseDate.getFullYear(),
        month: baseDate.getMonth(),
        date: baseDate.getDate(),
      });
    });
  }, [baseDate]);

  function convertTo24HourFormat(hour: number, period: "AM" | "PM") {
    if (period === "AM") {
      return hour % 12; // 12 AM is 0:00 hours
    } else {
      return (hour % 12) + 12; // 12 PM is 12:00 hours
    }
  }

  const updateHours = useCallback(
    (hour: number) => {
      const newHour = convertTo24HourFormat(hour, selectedPeriod);
      setTime((prevTime) => new Date(prevTime.setHours(newHour)));
    },
    [selectedPeriod],
  );

  const updateMinutes = useCallback(
    (minute: number) => {
      const newTime = new Date(time);
      newTime.setMinutes(minute);
      setTime(newTime);
    },
    [time],
  );

  const togglePeriod = useCallback(
    (period: "AM" | "PM") => {
      const newHour = convertTo24HourFormat(selectedHour, period);
      setTime((prevTime) => new Date(prevTime.setHours(newHour)));
    },
    [selectedHour],
  );

  const handleTimeSave = useCallback(() => {
    onTimeSelected(time);
  }, [onTimeSelected, time]);

  return (
    <>
      <div className="flex bg-white p-2 rounded-lg shadow-md">
        <div className="flex flex-col items-center flex-1 overflow-y-auto overflow-x-hidden max-h-48 px-1">
          {hours.map((hour) => (
            <button
              key={hour}
              onClick={() => updateHours(hour)}
              className={`mb-1 px-4 py-2 border rounded ${
                selectedHour === hour ? "bg-accent text-white" : "border-gray-300 hover:bg-gray-100"
              }`}
            >
              {hour}
            </button>
          ))}
        </div>
        <div className="flex flex-col items-center flex-1 overflow-y-auto overflow-x-hidden max-h-48 px-1">
          {minutes.map((minute) => (
            <button
              key={minute}
              onClick={() => updateMinutes(minute)}
              className={`mb-1 px-4 py-2 border rounded ${
                selectedMinute === minute ? "bg-accent text-white" : "border-gray-300 hover:bg-gray-100"
              }`}
            >
              {minute.toString().padStart(2, "0")}
            </button>
          ))}
        </div>
        <div className="flex flex-col items-center flex-1">
          {periods.map((period) => (
            <button
              key={period}
              onClick={() => togglePeriod(period)}
              className={`mb-1 px-4 py-2 border rounded ${
                selectedPeriod === period ? "bg-accent text-white" : "border-gray-300 hover:bg-gray-100"
              }`}
            >
              {period}
            </button>
          ))}
        </div>
      </div>
      <div>
        <button onClick={handleTimeSave} className="px-4 py-2 bg-accent text-white rounded w-full">
          Set Time
        </button>
      </div>
    </>
  );
};

export default TimePicker;
