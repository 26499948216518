import Tippy from "@tippyjs/react";
import { formatISO } from "date-fns";
import React from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  fetchRRUsers,
  selectAllRrUsers,
  selectEmployeeById,
  selectIsManager,
  useFetchEmployeesQuery,
  useUpdateEmployeeMutation,
} from "store/reducers";
import { genderOptions, stateOptions } from "utils/employee_profile";

import { Button } from "./button";
import { Checkbox } from "./Checkbox";
import { EmployeeInput } from "./EmployeeInput";
import { IconButton } from "./iconButton";
import { LoadingSpinner } from "./LoadingSpinner";

type EmploymentBasisOption = {
  label: string;
  value: string;
};

const EMPLOYMENT_BASIS = [
  { label: "Full-time", value: "FULLTIME" },
  { label: "Part-time", value: "PARTTIME" },
  { label: "Casual", value: "CASUAL" },
] as const satisfies EmploymentBasisOption[];

export type EmployeeFormValues = {
  xero_FirstName: string;
  xero_LastName: string;
  phone: string;
  xero_Email: string;
  xero_StartDate: string;
  xero_EmploymentBasis: string;
  xero_tenantId: string;
  standard_hours: number;
  earnings_vocational: boolean;
  radreport_user: { value: number; label: string } | null;
  payroll_role: PayrollOption;
  attendance_enabled: boolean;
  notify_messages: boolean;

  xero_Gender: string;
  xero_DateOfBirth: string;
  xero_AddressLine1: string;
  xero_AddressLine2: string;
  xero_City: string;
  xero_Region: string;
  xero_PostalCode: number;
  xero_Country: string;

  xero_AccountName: string;
  xero_BSB: number;
  xero_AccountNumber: number;

  emergency_contact_name: string;
  emergency_contact_phone: string;
  emergency_contact_email: string;
  emergency_contact_relationship: string;

  xero_SuperFundName: string;
  xero_SuperFundID: string;
  xero_SuperFundEmployeeNumber: string;
};

type PayrollOption = {
  value: string;
  label: string;
};

export const payrollRoleOptions: PayrollOption[] = [
  { value: "employee", label: "Employee" },
  { value: "manager", label: "Manager" },
  { value: "owner", label: "Owner" },
] as const satisfies PayrollOption[];

type Props = {
  employeeId: number;
  employees?: Employee[];
  onCancel?: () => void;
};

function EmployeeProfile(props: Props) {
  let { onCancel, employeeId } = props;
  const [updateEmployee, updateEmployeeResult] = useUpdateEmployeeMutation();
  const [rrUserOptions, setRRUserOptions] = React.useState<
    {
      value: number;
      label: string;
    }[]
  >();

  const dispatch = useAppDispatch();

  useFetchEmployeesQuery(undefined);

  const employee = useAppSelector((state) => selectEmployeeById(state, employeeId));

  React.useEffect(() => {
    dispatch(fetchRRUsers());
  }, [dispatch]);

  const isManager = useAppSelector(selectIsManager);

  const { register, handleSubmit, formState, control, watch, reset } = useForm<EmployeeFormValues>();

  React.useEffect(() => {
    if (!employee) return;
    // Use useEffect to ensure that the details are displayed correctly when the page is refreshed.
    const defaultValues = {
      xero_FirstName: employee.xero_FirstName,
      xero_LastName: employee.xero_LastName,
      phone: employee.phone,
      xero_Email: employee.xero_Email,
      xero_EmploymentBasis: EMPLOYMENT_BASIS[0].value,
      xero_StartDate: formatISO(new Date(), { representation: "date" }),
      standard_hours: employee.standard_hours || 75,
      earnings_vocational: employee.earnings_vocational || false,
      radreport_user: null,
      notify_messages: employee.notify_messages,
      payroll_role: payrollRoleOptions.find((option) => option.value === employee.payroll_role),
      attendance_enabled: employee.attendance_enabled || true,
      xero_DateOfBirth: employee.xero_DateOfBirth,
      xero_Gender: employee.xero_Gender || genderOptions[0]?.value,
      xero_AddressLine1: employee.xero_AddressLine1,
      xero_AddressLine2: employee.xero_AddressLine2,
      xero_City: employee.xero_City,
      xero_Region: employee.xero_Region || stateOptions[0]?.value,
      xero_PostalCode: employee.xero_PostalCode,
      xero_Country: employee.xero_Country ? employee.xero_Country : "Australia",

      xero_AccountName: employee.xero_AccountName,
      xero_BSB: employee.xero_BSB,
      xero_AccountNumber: employee.xero_AccountNumber,

      emergency_contact_name: employee.emergency_contact_name,
      emergency_contact_phone: employee.emergency_contact_phone,
      emergency_contact_email: employee.emergency_contact_email,
      emergency_contact_relationship: employee.emergency_contact_relationship,

      xero_SuperFundName: employee.xero_SuperFundName,
      xero_SuperFundEmployeeNumber: employee.xero_SuperFundEmployeeNumber,
    };
    reset(defaultValues);
  }, [employee, reset, rrUserOptions]);

  const { dirtyFields, errors } = formState;

  const rrUsers = useAppSelector(selectAllRrUsers);

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!rrUsers || !employee) return;
    setRRUserOptions(
      rrUsers.map((user) => {
        const isLinked = employee.radreport_user_id === user.id;
        return {
          value: user.id,
          label: `${user.name} - ${user.username}`,
          isDisabled: Boolean(isLinked),
        };
      }),
    );
  }, [rrUsers, employee]);

  React.useEffect(() => {
    if (!rrUserOptions || !employee) return;

    const defaultRRUser = rrUserOptions.find((e) => e.value === employee.radreport_user_id);

    // we have to set the default here with `setValue` because the options aren't available on first render
    // if (defaultRRUser) setValue("radreport_user", defaultRRUser);
    if (defaultRRUser) reset({ ...watch(), radreport_user: defaultRRUser });
    // reset() so that null is treated as dirty
  }, [rrUserOptions, employee, reset, watch]);

  const onSubmit: SubmitHandler<EmployeeFormValues> = async (data) => {
    const changedData = (Object.keys(data) as Array<keyof EmployeeFormValues>)
      .filter((k) => dirtyFields[k])
      .reduce((acc, k) => {
        if (k === "radreport_user") {
          acc.radreport_user_id = data[k]?.value ?? null;
        } else if (k === "payroll_role") {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          acc.payroll_role = data[k].value ?? null;
        } else {
          acc[k] = data[k];
        }
        return acc;
      }, {} as any);
    await updateEmployee({ employeeId, body: changedData });
    // reset data after update
    reset(data);

    if (onCancel) {
      onCancel();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-4">
      <section className="bg-white mb-3 rounded-md shadow">
        <div className="table-header-name">Employee Details</div>
        <div className="p-3 text-primary-dark p-3 text-primary-dark grid grid-cols-1 md:grid-cols-2 gap-4">
          <EmployeeInput label="First Name" name="xero_FirstName" required={true} register={register} errors={errors} />
          <EmployeeInput label="Last Name" name="xero_LastName" required={true} register={register} errors={errors} />
          <EmployeeInput label="Phone" name="phone" register={register} errors={errors} />
          <EmployeeInput label="Xero Email" name="xero_Email" register={register} errors={errors} />
          <EmployeeInput
            label="Date of Birth"
            name="xero_DateOfBirth"
            register={register}
            errors={errors}
            type="date"
          />

          <label>
            Gender
            <Controller
              control={control}
              name="xero_Gender"
              render={({ field }) => (
                <Select
                  {...field}
                  options={genderOptions}
                  menuPortalTarget={document.body}
                  onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                  value={genderOptions.find((option) => option.value === field.value)}
                />
              )}
            />
          </label>

          <EmployeeInput label="Address 1" name="xero_AddressLine1" register={register} errors={errors} />
          <EmployeeInput label="Address 2" name="xero_AddressLine2" register={register} errors={errors} />
          <EmployeeInput label="City" name="xero_City" register={register} errors={errors} />

          <label>
            State
            <Controller
              control={control}
              name="xero_Region"
              render={({ field }) => (
                <Select
                  {...field}
                  options={stateOptions}
                  menuPortalTarget={document.body}
                  onChange={(selectedOption) => field.onChange(selectedOption?.value)}
                  value={stateOptions.find((option) => option.value === field.value)}
                />
              )}
            />
          </label>

          <EmployeeInput label="Post Code" name="xero_PostalCode" register={register} errors={errors} type="number" />
          <EmployeeInput label="Country" name="xero_Country" register={register} errors={errors} disabled />
        </div>
      </section>

      <section className="bg-white mb-3 rounded-md shadow">
        <div className="table-header-name">Bank Account Details</div>
        <div className="p-3 text-primary-dark p-3 text-primary-dark grid grid-cols-1 md:grid-cols-2 gap-4">
          <EmployeeInput label="Account Name" name="xero_AccountName" register={register} errors={errors} />
          <EmployeeInput label="BSB" name="xero_BSB" register={register} errors={errors} type="number" />
          <EmployeeInput
            label="Account Number"
            name="xero_AccountNumber"
            register={register}
            errors={errors}
            type="number"
          />
        </div>
      </section>

      <section className="bg-white mb-3 rounded-md shadow">
        <div className="table-header-name">Superannuation Fund Details</div>
        <p className="p-3">Changes to Super Fund will not appear immediatelly. Please wait for it to be processed.</p>
        <div className="p-3 text-primary-dark grid grid-cols-1 md:grid-cols-2 gap-4">
          <EmployeeInput label="Fund Name" name="xero_SuperFundName" register={register} errors={errors} />
          <EmployeeInput
            label="Member Number"
            name="xero_SuperFundEmployeeNumber"
            register={register}
            errors={errors}
          />
          {/* <EmployeeInput
            label="Product Code"
            name="super_fund_product_code"
            register={register}
            errors={errors}
          /> */}
        </div>
      </section>

      <section className="bg-white mb-3 rounded-md shadow">
        <div className="table-header-name">Emergency Contact</div>
        <div className="p-3 text-primary-dark grid grid-cols-1 md:grid-cols-2 gap-4">
          <EmployeeInput
            label="Emergency Contact Name"
            name="emergency_contact_name"
            register={register}
            errors={errors}
          />
          <EmployeeInput
            label="Relationship"
            name="emergency_contact_relationship"
            register={register}
            errors={errors}
          />
          <EmployeeInput
            label="Emergency Contact Phone"
            name="emergency_contact_phone"
            register={register}
            errors={errors}
            type="number"
          />
          <EmployeeInput
            label="Emergency Contact Email"
            name="emergency_contact_email"
            register={register}
            errors={errors}
          />
        </div>
      </section>
      {/* Manager view */}
      {isManager && (
        <div style={{ maxWidth: 300 }} className="flex flex-col gap-2 mt-4 p-2">
          <EmployeeInput
            label="Standard Hours"
            name="standard_hours"
            required={true}
            register={register}
            errors={errors}
            type="number"
          />

          <Controller
            render={({ field: { onChange, value } }) => (
              <Checkbox onCheckedChange={(e) => onChange(e)} checked={value}>
                Vocational Training
              </Checkbox>
            )}
            name="earnings_vocational"
            control={control}
          />

          <Controller
            render={({ field: { onChange, value } }) => (
              <Checkbox onCheckedChange={(e) => onChange(e)} checked={value}>
                Attendance Enabled
              </Checkbox>
            )}
            name="attendance_enabled"
            control={control}
          />

          <Controller
            render={({ field: { onChange, value } }) => (
              <Checkbox onCheckedChange={(e) => onChange(e)} checked={value}>
                Notify Admin Messages
              </Checkbox>
            )}
            name="notify_messages"
            control={control}
          />

          <label>Payroll Role</label>
          <div>
            <Controller
              control={control}
              name="payroll_role"
              // Select props
              render={({ field }) => (
                <Select {...field} options={payrollRoleOptions} isClearable menuPortalTarget={document.body} />
              )}
            />
          </div>

          <label className="block">
            RadReport User
            <Tippy
              content={
                <div>
                  Must have <strong>Permissions Role</strong> and <strong>Company Role</strong> in RadReport to show up
                  in this list.
                </div>
              }
            >
              <IconButton type="button" icon="question-circle" />
            </Tippy>
          </label>
          <div>
            <Controller
              control={control}
              name="radreport_user"
              // Select props
              render={({ field }) => (
                <Select {...field} options={rrUserOptions} isClearable menuPortalTarget={document.body} />
              )}
            />
          </div>
        </div>
      )}
      <div
        className="grid gap-1 pt-1 p-2"
        style={{
          gridAutoColumns: "min-content",
          gridTemplateColumns: "repeat(auto-fit, 100px)",
        }}
      >
        {onCancel && (
          <Button type="button" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button colour="accent" type="submit">
          Update
        </Button>
        {updateEmployeeResult.isLoading && <LoadingSpinner className="self-center" />}
      </div>
    </form>
  );
}

export default EmployeeProfile;
